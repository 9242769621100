<template>
  <section id="contents">
    <b-row>
      <b-col>
        <b-alert
          v-model="showAlert"
          variant="danger"
          dismissible
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="FilmIcon"
            />
            <span class="ml-25">{{ $t("contents.contentAlert") }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-form-checkbox-group
      v-model="selectContent"
      name="some-radios"
    >
      <b-overlay
        variant="transparent"
        :show="overlay"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <template #overlay>
          <b-icon
            icon="stopwatch"
            variant="info"
            scale="2"
            shift-v="8"
            shift-h="8"
            class="position-absolute"
            style="top: 0; right: 0"
          />
        </template>
        <b-row v-if="!manual">
          <b-col
            v-for="folder in contentsList"
            :id="folder.node.id +
              ' ' +
              (folder.node.contentContentOrder.edges.length == 0
                ? folder.node.order
                : folder.node.contentContentOrder.edges[0].node.order)
            "
            :key="folder.node.id"
            class="mt-2"
            md="6"
            lg="4"
            xl="3"
            sm="12"
          >
            <b-card
              class="cardContent"
              no-body
              @click.stop.prevent="choice == false
                ? routeToEditView(folder.node)
                : clickCheck(folder.node)
              "
            >
              <b-img-lazy
                :src="buildImageUrl(folder)"
                class="card-img-top cursor-pointer"
                img-alt="Card image"
                fluid-grow
                @error="errorImg"
              />
              <b-card-header>
                <div id="acortarPadre">
                  <b-card-title
                    id="acortar"
                    class="mb-25"
                  >
                    <span
                      v-if="folder.node.state == 'GRE'"
                      class="mr-50 bullet bullet-success bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'YEW'"
                      class="mr-50 bullet bullet-warning bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'RED'"
                      class="mr-50 bullet bullet-danger bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'BCK'"
                      class="mr-50 bullet bullet-dark bullet-sm"
                    />
                    {{ folder.node.name }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    {{ $t(typeOptions[folder.node.type]) }}
                  </b-card-text>
                </div>
              </b-card-header>
              <b-card-body>
                <div
                  v-if="choice != true"
                  class="actions"
                >
                  <div>
                    <b-dropdown
                      :text="folder.node.state == 'GRE' ? $t('ecommerce.public') : folder.node.state == 'RED' ? $t('ecommerce.NoPublic') : $t('dataGeneric.internal')"
                      :variant="folder.node.state == 'GRE' ? 'outline-success' : folder.node.state == 'RED' ? 'outline-danger' : 'outline-warning'"
                      class="p-2"
                      size="sm"
                    >
                      <b-dropdown-item @click.stop.prevent="setIsActive(folder.node, 'RED')">
                        🔴 {{ $t('ecommerce.NoPublic') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="setIsActive(folder.node, 'YEW')">
                        🟡 {{ $t('dataGeneric.internal') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="setIsActive(folder.node, 'GRE')">
                        🟢 {{ $t('ecommerce.public') }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <feather-icon
                      v-if="folder.node.state == 'GRE'"
                      v-b-tooltip.hover.top.v-primary="released(folder.node)
                        ? $t('contVis')
                        : $t('contOc')
                      "
                      class="text-white"
                      :icon="released(folder.node) ? 'EyeIcon' : 'EyeOffIcon'"
                    />
                  </div>
                  <div class="action">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          color="white"
                          icon="MoreVerticalIcon"
                          size="20"
                        />
                      </template>
                      <b-dropdown-item
                        @click.stop.prevent="routeToEditView(folder.node)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          size="18"
                        />
                        {{ $t("contents.editContent") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="dropContentByCategory(folder)">
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t("contents.quitContent") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="showModal(folder.node, false)">
                        <feather-icon
                          icon="CornerRightUpIcon"
                          size="18"
                        />
                        {{ $t("contents.moveContent") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="showModal(folder.node, true)">
                        <feather-icon
                          icon="FolderPlusIcon"
                          size="18"
                        />
                        {{ $t('addToCategory') }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="!isExternalLive(folder.node)"
                        @click.stop.prevent="deleteContent(folder.node.id)"
                      >
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t("contents.dropContent") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div
                  v-else
                  class="actions right"
                >
                  <div
                    class="action"
                    @click.stop.prevent="clickCheck(folder.node)"
                  >
                    <b-form-checkbox
                      class="ml-2"
                      disabled
                      :value="folder.node.id"
                    />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <draggable
          v-else
          v-model="contentsList"
          class="row manualOrder rounded"
        >
          <b-col
            v-for="folder in contentsList"
            :id="folder.node.id +
              ' ' +
              (folder.node.contentContentOrder.edges.length == 0
                ? folder.node.order
                : folder.node.contentContentOrder.edges[0].node.order +
                  ' ' +
                  folder.node.contentContentOrder.edges[0].node.id)
            "
            :key="folder.node.id"
            class="mt-2 hang-on-hover"
            md="6"
            lg="4"
            xl="3"
            sm="12"
            @dragend="updateOrder($event)"
          >
            <b-card
              class="cardContent cursor-move"
              no-body
            >
              <b-img-lazy
                :src="buildImageUrl(folder)"
                class="card-img-top"
                img-alt="Card image"
                fluid-grow
                @error="errorImg"
              />
              <b-card-header>
                <div id="acortarPadre">
                  <b-card-title
                    id="acortar"
                    class="mb-25"
                  >
                    <span
                      v-if="folder.node.state == 'GRE'"
                      class="mr-50 bullet bullet-success bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'YEW'"
                      class="mr-50 bullet bullet-warning bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'RED'"
                      class="mr-50 bullet bullet-danger bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'BCK'"
                      class="mr-50 bullet bullet-dark bullet-sm"
                    />
                    {{ folder.node.name }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    {{ $t(typeOptions[folder.node.type]) }}
                  </b-card-text>
                </div>
              </b-card-header>
            </b-card>
          </b-col>
        </draggable>
      </b-overlay>
    </b-form-checkbox-group>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :exclude-ids="code"
          :type="true"
          :sponsor="sponsor"
          :especial="true"
          :columns="categorySelectorTable"
          @confirm-selection="confirm"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BImgLazy,
  BAlert,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  VBTooltip,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BDropdown,
  BIcon,
  BDropdownItem,
  BOverlay,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, deactivateSlider, moveToCategory, removeRelation, addContentsToCategory, utf8ToB64, checkContents, showToast, removeResources, isExternalLive,
} from '@/store/functions'
import draggable from 'vuedraggable'
import configContentType from '@core/utils/contentType'
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import CategorySelector from '../../common/CategorySelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BAlert,
    BIcon,
    draggable,
    BImgLazy,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BCard,
    BCardBody,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BCol,
    BRow,
    BButton,
    CategorySelector,
  },
  computed: {
    ...mapGetters('app', ['sizeWindow']),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    manual: {
      type: Boolean,
      default: false,
    },
    sponsor: {
      type: Boolean,
      default: false,
    },
    code: {
      default: '',
    },
    actions: {
      type: String,
      default: null,
    },
    contenidos: {
      type: Array,
      default: () => [],
    },
    choice: {
      type: Boolean,
      default: false,
    },
    todos: {
      type: Boolean,
      default: false,
    },
    isPod: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overlay: this.loading,
      clickContent: null,
      cardmoveId: [],
      cardmoveOrder: [],
      selectContent: [],
      infoContent: [],
      orderOrig: null,
      categoryName: null,
      typeOptions: configContentType(true, false),
      idOrig: null,
      currentParentFolder: null,
      perPage: 20,
      currentPage: 1,
      rows: null,
      catContentId: [],
      userData: getUserData(),
      allFinalCategories: null,
      showSelector: false,
      addCat: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'type', label: this.$t('type') },
      ],
      showAlert: false,
      contentsList: this.contenidos,
    }
  },
  watch: {
    contenidos() {
      this.contentsList = this.contenidos
    },
    choice() {
      if (!this.choice) this.selectContent = []
      this.infoContent = []
    },
    loading() {
      this.overlay = this.loading
    },
    todos() {
      if (this.todos) {
        this.selectContent = []
        this.infoContent = []
        this.contentsList.forEach(element => {
          this.clickCheck(element.node, true)
        })
      } else {
        this.selectContent = []
        this.infoContent = []
      }
      this.$emit('choices', [this.selectContent, this.infoContent])
    },
    actions() {
      switch (this.actions) {
        case 'ADD':
          this.showModal(null, true)
          break
        case 'MOV':
          this.showModal(null, false)
          break
        case 'REM':
          this.dropContentByCategory()
          break
        case 'DEL':
          this.deleteContent()
          break

        default:
          break
      }
      this.$emit('actReturn', null)
    },
  },
  mounted() {
    if (!this.todos) this.selectContent = []
  },
  methods: {
    isExternalLive,
    clickCheck(node, type = null) {
      if (type == null) {
        const i = this.selectContent.indexOf(node.id)
        const a = this.infoContent.map(e => e.id).indexOf(node.id)

        if (i !== -1) {
          this.selectContent.splice(i, 1)
          this.infoContent.splice(a, 1)
        } else {
          this.selectContent.push(node.id)
          this.infoContent.push(node)
        }
        this.$emit('choices', [this.selectContent, this.infoContent])
      } else {
        const i = this.selectContent.indexOf(node.id)
        const a = this.infoContent.map(e => e.id).indexOf(node.id)

        if (i !== -1) {
          this.selectContent.splice(i, 1)
          this.infoContent.splice(a, 1)
        } else {
          this.selectContent.push(node.id)
          this.infoContent.push(node)
        }
      }
    },

    setIsActive(node, value) {
      const { id, contentSlide } = node
      let isActive = false
      let active = false
      switch (value) {
        case 'RED':
          isActive = false
          active = false
          break
        case 'YEW':
          isActive = true
          active = false
          break
        default:
          isActive = true
          active = true

          break
      }
      if (contentSlide.totalCount !== 0 && !active) {
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('desContSlider'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            const query = `mutation{
                updateContents(id:"${id}",input:{isActive:${isActive},state:${value}}){
                  content{
                    id
                    name
                    isActive
                  }
                }
              }`
            axios
              .post('', { query }).then(() => {
                showToast(this.$t('success'), 1, this)

                deactivateSlider([id], false, 'CONT').then(() => {
                }).catch(err => {
                  console.log(err)
                })
                if (this.contentsList.length === 0) {
                  this.$emit('vacio', true)
                } else {
                  const indice = this.contentsList.findIndex(objeto => objeto.node.id === id)
                  if (indice !== -1) {
                    this.contentsList[indice].node.state = value
                  }
                }
              }).catch(() => {
                showToast(this.$t('error'), 0, this)
              })
          }
        }).catch(() => {
          showToast(this.$t('error'), 0, this)
        })
      } else {
        const query = `mutation{
                updateContents(id:"${id}",input:{isActive:${isActive},state:${value}}){
                  content{
                    id
                    name
                    isActive
                  }
                }
              }`
        axios
          .post('', { query }).then(() => {
            showToast(this.$t('success'), 1, this)
            if (this.contentsList.length === 0) {
              this.$emit('vacio', true)
            } else {
              const indice = this.contentsList.findIndex(objeto => objeto.node.id === id)
              if (indice !== -1) {
                this.contentsList[indice].node.state = value
              }
            }
          }).catch(() => {
            showToast(this.$t('error'), 0, this)
          })
      }
    },
    dropContentByCategory(content = null) {
      if (content != null) this.selectContent.push(content.node.id)
      this.$swal({
        title: this.$t('removeFromCat'),
        text:
          this.$t('sureOrphan'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            removeRelation(this.code, this.selectContent).then(res => {
              console.log(res)
              messageError(res, this)
              showToast(this.$t('success'), 1, this)

              this.selectContent = []
              this.infoContent = []
              this.$emit('reload', this.code)
            }).catch(() => {

            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    routeToEditView(node) {
      const { id } = node

      this.$router.push({ name: 'contents-edit', params: { id } })
    },

    deleteContent(id = null) {
      if (id != null) {
        this.selectContent = []
        this.infoContent = []
        this.selectContent.push(id)
      }
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.overlay = true
          let query = '{'
          this.selectContent.forEach((element, i) => {
            query += `cont_${i
              + 1}: allContents(id:"${element}") {
                edges {
                  node {
                    id
                    name
                    contentResources {
                      totalCount
                      edges {
                        node {
                          id              
                          resource {
                            id
                            name
                            file {
                              id
                              name
                            }
                            resourceContent {
                              totalCount
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }      
                  }
                }
              }`
          })
          query += '}'
          axios
            .post('', {
              query,
            }).then(result2 => {
              messageError(result2, this)

              const contentResources = result2.data.data

              removeResources(contentResources).then(resultado => {
                if (resultado) {
                  this.$swal({
                    title: this.$t('code.title'),
                    text: this.$t('code.resourceOrphan', { resourceName: resultado.name }),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('code.confirm'),
                    cancelButtonText: this.$t('dataGeneric.cancel'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(response => {
                    if (response.value) {
                      let query2 = 'mutation{'
                      resultado.id.forEach((element, i) => {
                        query2 += `
                                resource_${i + 1}: deleteResource(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                      })
                      if (resultado.file.length) {
                        resultado.file.forEach((element, i) => {
                          query2 += `
                                file_${i + 1}: deleteFile(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                        })
                      }
                      query2 += '}'
                      axios.post('', { query: query2 }).then(r => {
                        messageError(r, this)

                        this.delete()
                      }).catch(err => {
                        console.log(err)
                      })
                    } else {
                      this.delete()
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  this.delete()
                }
              }).catch(err => {
                console.log(err)
              })
            }).catch(err => {
              console.log(err)
            })
        }
      })
    },
    delete() {
      checkContents(this.selectContent).then(() => {
        let query = 'mutation{'
        this.selectContent.forEach((element, i) => {
          query += `a_${i
            + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
        })
        query += '}'
        axios
          .post('', {
            query,
          })
          .then(res => {
            messageError(res, this)

            this.selectContent.forEach(element => {
              this.contentsList = this.contentsList.filter(
                item => item.node.id !== element,
              )
            })

            if (this.contentsList.length === 0) {
              this.$emit('vacio', true)
            } else {
              this.$emit('reload', this.code)
            }

            this.selectContent = []
            this.infoContent = []
            this.overlay = false
            showToast(this.$t('success'), 1, this)
          })
          .catch(() => {
            showToast(this.$t('error'), 2, this)

            this.overlay = false
          })
      }).catch(err => {
        console.log(err)
        this.overlay = false
      })
    },
    showModal(content, boleano) {
      if (content != null) {
        this.selectContent.push(content.id)
        this.infoContent.push(content)
        /* if (content.type === 'POD' || content.type === 'AUC') {
          this.isPod = true
        } else {
          this.isPod = false
        } */
      }
      this.$refs['my-modal'].show()
      this.addCat = boleano
    },
    confirm(data) {
      this.hideModal()
      if (this.addCat) {
        addContentsToCategory(data.node.id, this.selectContent).then(result => {
          messageError(result, this)
          showToast(`${this.$t('contentsAddToCat')} ${data.node.name}`, 1, this)
          this.selectContent = []
          this.infoContent = []
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
        })
      } else {
        moveToCategory(this.code, data.node.id, this.selectContent, 1).then(() => {
          this.selectContent = []
          this.infoContent = []
          this.$emit('reload', this.code)
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
        })
      }
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    changeTypeOrder() {
      axios.post('', {
        query: `
          mutation{
          updateCategory(id:"${this.code}",input:{orderType:MAN})  {
            category {
              id
            }
          }
          }
         `,
      }).then(res => {
        messageError(res, this)
      })
    },
    updateOrder(event) {
      this.changeTypeOrder()
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < event.target.parentNode.childNodes.length; i += 1) {
        arrayId.push(event.target.parentNode.childNodes[i].id.split(' ')[0])
        arrayNum.push(event.target.parentNode.childNodes[i].id.split(' ')[1])
      }

      const result = ['0']

      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      // Comprobar si arrayId[i] idCategoría existe en relación:

      let query = '{'
      for (let id = 0; id < arrayId.length; id += 1) {
        const code = utf8ToB64(`id:${this.code}`)
        const cont = utf8ToB64(`id:${arrayId[id]}`)
        query += `
        m${id + 1}:  allContentOrder(category:"${code}",content:"${cont}") {
          totalCount
            edges {
              node {
                id
                content {
                  id
                }
                category {
                  id
                }
                order
              }
            }
          }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          const editar = []

          for (
            let index = 0;
            index < Object.keys(res.data.data).length;
            index += 1
          ) {
            if (res.data.data[`m${index + 1}`].edges.length !== 0) {
              const idRe = res.data.data[`m${index + 1}`].edges[0].node.id
              const ord = res.data.data[`m${index + 1}`].edges[0].node.order
              const conteOrd = res.data.data[`m${index + 1}`].edges[0].node.content.id

              // Si existe, se comprueba si arrayNum[i] es igual a order, si no lo es se edita, si es igual no
              for (let e = 0; e < arrayId.length; e += 1) {
                if (conteOrd === arrayId[e]) {
                  if (ord === result[e]) {
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  } else {
                    editar.push({ id: idRe, order: result[e] })
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  }
                }
              }
            }
          }

          if (editar.length !== 0) {
            let query2 = 'mutation{'
            for (let a = 0; a < editar.length; a += 1) {
              query2 += `
          m${a + 1}: updateContentOrder(id:"${editar[a].id}",input:{order:${editar[a].order
}}){
              contentOrder{
                id
              }
            }`
            }
            query2 += '}'
            axios
              .post('', {
                query: query2,
              })
              .then(result2 => {
                messageError(result2, this)

                showToast(this.$t('success'), 1, this)
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }

          // Si no existe se crea la relación
          if (arrayId.length !== 0) {
            const code = utf8ToB64(`id:${this.code}`)
            let query2 = ` mutation
            {`
            for (let i = 0; i < result.length; i += 1) {
              const cont = utf8ToB64(`id:${arrayId[i]}`)
              query2 += `
              m${i + 1}: createContentOrder(input:{ order: ${result[i]
},content:"${cont}",category:"${code}"}){
                          contentOrder{
                            id
                           content {
                              id
                            }
                            category {
                              id
                            }
                            order
                          }
                        }
                      `
            }
            query2 += `
            }`
            axios
              .post('', {
                query: query2,
              })
              .then(result2 => {
                messageError(result2, this)

                showToast(this.$t('success'), 1, this)
                this.$emit('reload', this.code)
              })
              .catch(err => {
                showToast(this.$t('error'), 2, this)
                console.log(err)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    specialContent() {
      this.$swal({
        title: this.$t('code.atention'),
        text: this.$t('myChannelContent'),
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {

      }).catch(() => {

      })
    },
    errorImg(e) {
      e.target.src = fileError
      // "https://cdn.iconscout.com/icon/free/png-256/file-error-1470245-1244839.png";
    },
    released(data) {
      const inicial = data.publishDate == null ? null : new Date(data.publishDate)
      const final = data.expirationDate == null ? null : new Date(data.expirationDate)
      const DateToCheck = new Date()

      if (inicial == null || inicial < DateToCheck) {
        if (final == null || final > DateToCheck) {
          return true
        }
        return false
      }
      return false
    },
  },
}
</script>

<style scoped>
#contents .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#contents .cardContent img {
  height: 11vw;
  object-fit: cover;

}

@media screen and (max-width: 767px) {
  #contents .cardContent img {
    height: 40vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 767px) {

  #contents .cardContent img {
    height: 23vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1240px) {

  #contents .cardContent img {
    height: 12vw;
    object-fit: cover;

  }
}

#contents .card-body {
  padding: 1rem;
}

#contents .card-body h4 {
  font-size: 1rem !important;
}

#contents .card-header {
  padding: 1rem;
}

#contents .card-header h4 {
  font-size: 1rem !important;
}

#contents .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

#contents .dropContainer {
  border: 3px dashed;
}

#contents .list-group-item {
  transition: all 1s;
}

#contents #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#contents #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

@-webkit-keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hang-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hang-on-hover:hover,
.hang-on-hover:focus,
.hang-on-hover:active {
  -webkit-animation-name: hang-on-hover-sink, hang-on-hover;
  animation-name: hang-on-hover-sink, hang-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
</style>
