<template>
  <section id="categories-alert">
    <b-alert
      v-model="showAlertUpdate"
      variant="danger"
      dismissible
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="FolderMinusIcon"
        />
        <span class="ml-25">{{ $t('needDelCat') }}</span>
      </div>
    </b-alert>
    <b-alert
      v-model="showAlertContentUpdate"
      variant="danger"
      dismissible
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="FilmIcon"
        />
        <span class="ml-25">{{ $t('needDelCont') }}</span>
      </div>
    </b-alert>
  </section>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    showAlert: Boolean,
    showAlertContent: Boolean,
  },
  data() {
    return {
      showAlertUpdate: this.showAlert,
      showAlertContentUpdate: this.showAlertContent,
    }
  },
}
</script>
