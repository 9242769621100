import { render, staticRenderFns } from "./CategoriesList.vue?vue&type=template&id=6bdf4bee&scoped=true"
import script from "./CategoriesList.vue?vue&type=script&lang=js"
export * from "./CategoriesList.vue?vue&type=script&lang=js"
import style0 from "./CategoriesList.vue?vue&type=style&index=0&id=6bdf4bee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdf4bee",
  null
  
)

export default component.exports