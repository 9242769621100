<template>
  <section id="contents">
    <b-table-simple
      ref="refContentListTable"
      show-empty
      hover
      selectable
      :select-mode="single"
      :empty-text="$t('emptyText')"
      responsive
      primary-key="id"
      :no-border-collapse="true"
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="i in tableColumns"
            :key="i.key"
          >
            {{ i.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="contentsList"
        tag="tbody"
        handle=".handle"
      >
        <b-tr
          v-for="item in contentsList"
          :key="item.node.id"
          @dragend="updateOrder"
        >
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            <div
              class="d-flex"
              style="align-items: center"
            >
              <b-img
                id="avatar"
                class="mr-2 ml-2"
                rounded="lg"
                :src="buildImageUrl(item)"
                @error="errorImg"
              />
              <p>{{ item.node.name }}</p>
            </div>
          </b-td>
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            <span
              v-if="item.node.state == 'GRE'"
              class="text-success"
            >{{ $t('ecommerce.public') }}</span>
            <span
              v-if="item.node.state == 'YEW'"
              class="text-warning"
            >{{ $t('dataGeneric.internal') }}</span>
            <span
              v-if="item.node.state == 'RED'"
              class="text-danger"
            >{{ $t('ecommerce.NoPublic') }}</span>
            <span
              v-if="item.node.state == 'BCK'"
              class="text-dark"
            >{{ $t('ecommerce.NoPublic') }}</span>
          </b-td>
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            {{ fechaFormato(item.node.emisionDate)
            }}
          </b-td>
          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            {{
              fechaFormato(item.node.publishDate)
            }}
          </b-td>

          <b-td @click="!manual ? onRowSelected(item.node.id) : ''">
            {{
              fechaFormato(item.node.expirationDate)
            }}
          </b-td>
          <b-td>
            <i
              v-if="manual"
              class="fa fa-align-justify handle cursor-move text-warning"
            />
            <b-dropdown
              v-else
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  color="white"
                  icon="MoreVerticalIcon"
                  size="20"
                />
              </template>
              <b-dropdown-item @click.prevent="dropContentByCategory(item)">
                <feather-icon
                  icon="DeleteIcon"
                  size="18"
                />
                {{ $t("contents.quitContent") }}
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="showModal(item.node, false)">
                <feather-icon
                  icon="CornerRightUpIcon"
                  size="18"
                />
                {{ $t("contents.moveContent") }}
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="showModal(item.node, true)">
                <feather-icon
                  icon="FolderPlusIcon"
                  size="18"
                />
                {{ $t('addCategory') }}
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="onRowSelected(item.node.id)">
                <feather-icon
                  icon="Edit2Icon"
                  size="18"
                />
                {{ $t("contents.editContent") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!isExternalLive(item.node)"
                @click.prevent="deleteContent(item.node.id)"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="18"
                />
                {{ $t("contents.dropContent") }}
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :type="true"
          :sponsor="sponsor"
          :especial="true"
          :columns="categorySelectorTable"
          @confirm-selection="confirm"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BImg,
  BButton,
  BDropdown,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, addContentsToCategory, showToast, moveToCategory, utf8ToB64, removeResources, checkContents, isExternalLive,
} from '@/store/functions'

import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'
import CategorySelector from '../../common/CategorySelector.vue'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BImg,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    draggable,
    BDropdown,
    BDropdownItem,
    BButton,
    CategorySelector,
  },
  props: {
    sponsor: {
      type: Boolean,
      default: false,
    },
    code: {
      default: '',
    },
    manual: Boolean,
    contenidos: {
      type: Array,
      default: () => [],
    },
    isPod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pod: this.isPod,
      overlay: false,
      clickContent: null,
      single: 'single',
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      folders: null,
      addCat: false,
      categoryName: null,
      categoryId: null,
      currentParentFolder: null,
      perPage: 20,
      currentPage: 1,
      rows: null,
      allFinalCategories: null,
      showSelector: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'type', label: this.$t('type') },
      ],
      showAlert: false,
      selectContent: [],
      infoContent: [],
      userData: getUserData(),
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'state', label: this.$t('editContent.status') },
        { key: 'emision', label: this.$t('contents.datePublishing') },
        { key: 'date_publication', label: this.$t('contents.datePublication') },
        { key: 'date_final', label: this.$t('contents.dateFinishing') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ],
      contentsList: this.contenidos,

    }
  },
  watch: {
    contenidos() {
      this.contentsList = this.contenidos
    },
  },

  methods: {
    isExternalLive,
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },

    onRowSelected(items) {
      this.$router.push({
        name: 'contents-edit',
        params: { id: items },
      })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },

    deleteContent(id = null) {
      if (id != null) {
        this.selectContent = []
        this.infoContent = []
        this.selectContent.push(id)
      }
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = '{'
          this.selectContent.forEach((element, i) => {
            query += `cont_${i
              + 1}: allContents(id:"${element}") {
                edges {
                  node {
                    id
                    name
                    contentResources {
                      totalCount
                      edges {
                        node {
                          id
                          resource {
                            id
                            name
                            file {
                              id
                              name
                            }
                            resourceContent {
                              totalCount
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }`
          })
          query += '}'
          axios
            .post('', {
              query,
            }).then(r => {
              messageError(r, this)

              const contentResources = r.data.data

              removeResources(contentResources).then(resultado => {
                if (resultado) {
                  this.$swal({
                    title: this.$t('code.title'),
                    text: this.$t('code.resourceOrphan', { resourceName: resultado.name }),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('code.confirm'),
                    cancelButtonText: this.$t('dataGeneric.cancel'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(response => {
                    if (response.value) {
                      let qe = 'mutation{'
                      resultado.id.forEach((element, i) => {
                        qe += `
                                resource_${i + 1}: deleteResource(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                      })
                      if (resultado.file.length) {
                        resultado.file.forEach((element, i) => {
                          qe += `
                                file_${i + 1}: deleteFile(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                        })
                      }
                      qe += '}'
                      axios.post('', { query: qe }).then(res => {
                        messageError(res, this)

                        this.delete()
                      }).catch(err => {
                        console.log(err)
                      })
                    } else {
                      this.delete()
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  this.delete()
                }
              }).catch(err => {
                console.log(err)
              })
            }).catch(err => {
              console.log(err)
            })
        }
      })
    },
    delete() {
      checkContents(this.selectContent).then(() => {
        let query = 'mutation{'
        this.selectContent.forEach((element, i) => {
          query += `a_${i
            + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
        })
        query += '}'
        axios
          .post('', {
            query,
          })
          .then(res => {
            messageError(res, this)

            this.selectContent.forEach(element => {
              this.contentsList = this.contentsList.filter(
                item => item.node.id !== element,
              )
            })
            this.selectContent = []
            this.infoContent = []
          })
          .catch(() => {
          })
      }).catch(err => {
        console.log(err)
      })
    },
    dropContentByCategory(content) {
      this.$swal({
        title: this.$t('removeFromCat'),
        text:
          this.$t('sureOrphan'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const categories = content.node.categories.edges.filter(
            element => element.node.id !== this.code,
          )
          const res = []
          categories.forEach(item => res.push(item.node.id))
          axios
            .post('', {
              query: `
          mutation{
            updateContents(id: "${content.node.id}", input:{
              categories: [${res}]
            }) {
              content{
                id
                name
                client {
                  id
                  name
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `,
            })
            .then(response => {
              messageError(response, this)

              this.contentsList = this.contentsList.filter(
                item => item.node.id !== content.node.id,
              )
              /* ELIMINAR RELACIÓN CONTENTORDER */
              try {
                const idRel = content.node.contentContentOrder.edges[0].node.id
                axios
                  .post('', {
                    query: `
            mutation{
              deleteContentOrder(id:"${idRel}") {
                found
                deletedId
              }
            }
        `,
                  })
                  .then(r => {
                    messageError(r, this)
                  })
                  .catch(error => {
                    console.log(error)
                  })
                // eslint-disable-next-line no-empty
              } catch (error) { }
            })
            .catch(err => {
              console.log(err)
            })
        }
      }).catch(() => {

      })
    },
    showModal(content, boleano) {
      if (content.type === 'POD' || content.type === 'AUC') {
        this.pod = true
      } else {
        this.pod = false
      }
      if (content != null) {
        this.selectContent.push(content.id)
        this.infoContent.push(content)
      }
      this.$refs['my-modal'].show()
      this.addCat = boleano
    },

    errorImg(e) {
      e.target.src = fileError
      // "https://cdn.iconscout.com/icon/free/png-256/file-error-1470245-1244839.png";
    },
    confirm(data) {
      this.hideModal()
      if (this.addCat) {
        addContentsToCategory(data.node.id, this.selectContent).then(result => {
          messageError(result, this)
          showToast(`${this.$t('contentsAddToCat')} ${data.node.name}`, 1, this)
          this.selectContent = []
          this.infoContent = []
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
        })
      } else {
        moveToCategory(this.code, data.node.id, this.selectContent, 1).then(() => {
          this.selectContent = []
          this.infoContent = []
          this.$emit('reload', this.code)
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
        })
      }
      // this.addCat ? this.addToCategory(data) : this.moveToCategory(data);
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    updateOrder() {
      this.changeTypeOrder()
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < this.contentsList.length; i += 1) {
        arrayId.push(this.contentsList[i].node.id)
        arrayNum.push(
          this.contentsList[i].node.contentContentOrder.edges.length !== 0
            ? this.contentsList[i].node.contentContentOrder.edges[0].node
              .order
            : this.contentsList[i].node.order,
        )
      }
      const result = ['0']

      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      // Comprobar si arrayId[i] idCategoría existe en relación:

      let query = '{'
      for (let id = 0; id < arrayId.length; id += 1) {
        const code = utf8ToB64(`id:${this.code}`)
        const cont = utf8ToB64(`id:${arrayId[id]}`)
        query += `
        m${id + 1}:  allContentOrder(category:"${code}",content:"${cont}") {
          totalCount
            edges {
              node {
                id
                content {
                  id
                }
                category {
                  id
                }
                order
              }
            }
          }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          const editar = []

          for (
            let index = 0;
            index < Object.keys(res.data.data).length;
            index += 1
          ) {
            if (res.data.data[`m${index + 1}`].edges.length !== 0) {
              const idRe = res.data.data[`m${index + 1}`].edges[0].node.id
              const ord = res.data.data[`m${index + 1}`].edges[0].node.order
              const conteOrd = res.data.data[`m${index + 1}`].edges[0].node.content.id

              // Si existe, se comprueba si arrayNum[i] es igual a order, si no lo es se edita, si es igual no
              for (let e = 0; e < arrayId.length; e += 1) {
                if (conteOrd === arrayId[e]) {
                  if (ord === result[e]) {
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  } else {
                    editar.push({ id: idRe, order: result[e] })
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  }
                }
              }
            }
          }

          if (editar.length !== 0) {
            query = 'mutation{'
            for (let a = 0; a < editar.length; a += 1) {
              query += `
          m${a + 1}: updateContentOrder(id:"${editar[a].id}",input:{order:${editar[a].order
}}){
              contentOrder{
                id
              }
            }`
            }
            query += '}'
            axios
              .post('', {
                query,
              })
              .then(resp => {
                messageError(resp, this)

                if (arrayId.length === 0) this.$emit('reload', this.code)
              })
              .catch(() => { })
          }

          // Si no existe se crea la relación
          if (arrayId.length !== 0) {
            const code = utf8ToB64(`id:${this.code}`)
            query = ` mutation
            {`
            for (let i = 0; i < result.length; i += 1) {
              const cont = utf8ToB64(`id:${arrayId[i]}`)
              query += `
              m${i + 1}: createContentOrder(input:{ order: ${result[i]
},content:"${cont}",category:"${code}"}){
                          contentOrder{
                            id
                           content {
                              id
                            }
                            category {
                              id
                            }
                            order
                          }
                        }
                      `
            }
            query += `
            }`
            axios
              .post('', {
                query,
              })
              .then(resp => {
                messageError(resp, this)

                this.$emit('reload', this.code)
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeTypeOrder() {
      axios.post('', {
        query: `
          mutation{
          updateCategory(id:"${this.code}",input:{orderType:MAN})  {
            category {
              id
            }
          }
          }
         `,
      }).then(res => {
        messageError(res, this)
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>
